@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNova-Light.woff2') format('woff2'),
        url('ProximaNova-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNova-Regular.woff2') format('woff2'),
        url('ProximaNova-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNovaT-Thin.woff2') format('woff2'),
        url('ProximaNovaT-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNova-Bold.woff2') format('woff2'),
        url('ProximaNova-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNova-Semibold.woff2') format('woff2'),
        url('ProximaNova-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNova-BoldIt.woff2') format('woff2'),
        url('ProximaNova-BoldIt.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNova-ThinIt.woff2') format('woff2'),
        url('ProximaNova-ThinIt.woff') format('woff');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNova-SemiboldIt.woff2') format('woff2'),
        url('ProximaNova-SemiboldIt.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNova-LightIt.woff2') format('woff2'),
        url('ProximaNova-LightIt.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNova-RegularIt.woff2') format('woff2'),
        url('ProximaNova-RegularIt.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

